<template>
  <div>
    <div style="padding-top: 50px;">
      <div style="font-size: 16pt;">Angelo Farias</div>


        <div style="font-size: 14pt;margin-top: 20px;">Faça a leitura da tag para liberar</div>
        <div style="margin-top: 5px;overflow: hidden;border-radius: 5px;">

          <qrcode-stream v-if="!loading" @decode="onDecode" @init="onInit" >

          </qrcode-stream>
          <div v-else>
            Verificando acesso...<br><br>
            {{result}}
          </div>
        </div>
    </div>
  </div>
</template>


<script setup>

import {ref} from 'vue';

const loading = ref(false);
const result = ref('');

const onDecode = (r)=>{
  loading.value = true;
  result.value = r;
}

const onInit = async (promise)=>{
  try {
    // eslint-disable-next-line no-unused-vars
    const { capabilities } = await promise


    console.log(capabilities);

    // successfully initialized
  } catch (error) {
    if (error.name === 'NotAllowedError') {
      // user denied camera access permisson
    } else if (error.name === 'NotFoundError') {
      // no suitable camera device installed
    } else if (error.name === 'NotSupportedError') {
      // page is not served over HTTPS (or localhost)
    } else if (error.name === 'NotReadableError') {
      // maybe camera is already in use
    } else if (error.name === 'OverconstrainedError') {
      // did you requested the front camera although there is none?
    } else if (error.name === 'StreamApiNotSupportedError') {
      // browser seems to be lacking features
    }
  } finally {
    // hide loading indicator
  }
}


</script>